<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12">
      	
      	<v-card class="transparent elevation-0">
      	  <v-card-title class="text-subtitle-1">
      	    Encuestas de satisfacción
      	    <v-spacer></v-spacer>

      	    <!-- Botón par aexportar -->
            <!-- <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()"
            >
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
            -->
            
      	  	<!-- Botón para consultar la iformación al sistema -->
      	    <v-btn 
              small dark color="primary"
              @click="consultar()"
            >
              Consultar
            </v-btn>

      	  </v-card-title>

      	  <!-- Tabla y filtros -->
      	  <v-card-text>

      	  	<!-- Filtro de ciclos -->
      	    <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <!-- Mostrar información por bloques -->
            <v-row justify="space-between">
            	<v-col cols="12" md="6" lg="6">
            		<v-radio-group
						      v-model="escuela"
						      row
						      hide-details
						    >
						      <v-radio
						        label="INBI"
						        :value="1"
						      ></v-radio>
						      <v-radio
						        label="FAST"
						        :value="2"
						      ></v-radio>
						    </v-radio-group>
            	</v-col>
            </v-row>

		        <!-- Tabla con toda la información -->

		        <!-- Barra superior -->
				  	<v-row>
				  		<v-col cols="12">
					  		<v-card class="">
				          <v-img
				            class="white--text align-end"
				          />
				          <v-card-text class="position-relative">
				          	<div class="d-flex flex-no-wrap ">

				              <div>
				                <v-card-title
				                  class="text-h6 text-typo"
				                >
				              		Se encuestaron a:
			              			<span class="text-h4 mx-2 text-gradient"> <b>{{ alumnosEncuestados }}</b> </span> alumnos 
			              			de: 
			              			<span class="text-h4 mx-2 text-gradient"> <b>{{ totalAlumnos }}</b> </span> 
			              			para un total de: 
			              			<span class="text-h4 mx-2 text-gradient"> <b>{{ porcentaje }} % </b></span> 
				              	</v-card-title>
				              </div>
				            </div>
				          </v-card-text>
				          <div class="check">
					          <v-switch 
					          	label="Gráficas" 
					          	v-model="verGraficaIniciales"
					          	fixed
					          ></v-switch>
				          </div>
				          <v-btn 
				          	color="primary"
				          	small
				          	class="v-btn--example"
				          	@click="abrirPreguntas( preguntasAbiertasGeneral, headersGeneral )"
				          >Ver Respuestas</v-btn>
				        </v-card>
				  		</v-col>
				  	</v-row>

				  	<!-- GRAFICAS INICIALES -->
				  	<v-row v-if="verGraficaIniciales">
				  		<v-col cols="12" :lg="`${pregunta.cols}`" v-for="(pregunta, i) in preguntasSeccion1" :key="i">
					  		<v-card class="">
					  			<v-card-title>
					  			  <span class="text-subtitle-1  text-typo"><b>{{ pregunta.pregunta }} </b></span>
					  			</v-card-title>
				          <v-card-text class="position-relative">
				          	<vue-apex-charts
					            :type="pregunta.chartOptions.chart.type" height="350" :options="pregunta.chartOptions" :series="pregunta.series"
					          ></vue-apex-charts>
				          </v-card-text>
				        </v-card>
				  		</v-col>
				  	</v-row>


				  	<!-- BARRA TEACHER A -->
				  	<v-row>
				  		<v-col cols="12">
					  		<v-card class="">
				          <v-card-text>
				          	<div class="d-flex flex-no-wrap ">
				              <div>
				                <v-card-title class="text-h6 text-typo"> SECCIÓN TEACHER <span class="text-h4 mx-2 text-gradient"> <b> A </b></span>
				              	</v-card-title>
				              </div>
				            </div>
					          <v-btn 
					          	color="primary"
					          	small
					          	class="v-btn--example"
					          	@click="abrirPreguntas( preguntasAbiertasTacherA, headersTeachers )"
					          >Ver Respuestas</v-btn>
				          </v-card-text>
				          <div class="check">
					          <v-switch 
					          	label="Gráficas" 
					          	v-model="verGraficaTeacherA"
					          	fixed
					          ></v-switch>
				          </div>
				          
				          <!-- BOTON PARA VER LAS RESPUESTAS ABIERTAS -->
				        </v-card>
				  		</v-col>
				  	</v-row>

				  	<!-- GRAFICAS TEACHER A -->
				  	<v-row v-if="verGraficaTeacherA">
				  		<v-col cols="12" :lg="`${pregunta.cols}`" v-for="(pregunta, i) in preguntasSeccion2" :key="i">
					  		<v-card class="">
					  			<v-card-title>
					  			  <span class="text-subtitle-1  text-typo"><b>{{ pregunta.pregunta }} </b></span>
					  			</v-card-title>
				          <v-card-text class="position-relative">
				          	<vue-apex-charts
					            :type="pregunta.chartOptions.chart.type" height="350" :options="pregunta.chartOptions" :series="pregunta.series"
					          ></vue-apex-charts>
				          </v-card-text>
				        </v-card>
				  		</v-col>
				  	</v-row>

				  	<!-- TABLAS TEACHER A -->
				  	<v-row v-if="verGraficaTeacherA">
				  		<v-col cols="12">
					  		<v-card class="">
					  			<v-card-title>
					  			  <span class="text-h6  text-gradient"><b> Porcentajes Teacher A </b></span>
					  			</v-card-title>
				          <v-card-text class="position-relative">
				          	<v-data-table
									    :headers="tablaTeacherA.headers"
									    :items="tablaTeacherA.teachers"
									    sort-by="calories"
									    class="elevation-0"
									    dense
									  >
									  </v-data-table>
				          </v-card-text>
				        </v-card>
				  		</v-col>
				  	</v-row>

				  	<!-- BARRA TEACHER B -->
				  	<v-row>
				  		<v-col cols="12">
					  		<v-card class="">
				          <v-img
				            class="white--text align-end"
				          />
				          <v-card-text class="position-relative">
				          	<div class="d-flex flex-no-wrap ">
				              <div>
				                <v-card-title class="text-h6 text-typo"> SECCIÓN TEACHER <span class="text-h4 mx-2 text-gradient"> <b> B</b></span>
				              	</v-card-title>
				              </div>
				            </div>
				            <v-btn 
					          	color="primary"
					          	small
					          	class="v-btn--example"
					          	@click="abrirPreguntas( preguntasAbiertasTacherB, headersTeachers )"
					          >Ver Respuestas</v-btn>
				          </v-card-text>
				          <div class="check">
					          <v-switch 
					          	label="Gráficas" 
					          	v-model="verGraficaTeacherB"
					          	fixed
					          ></v-switch>
				          </div>

				        </v-card>
				  		</v-col>
				  	</v-row>

				  	<!-- GRAFICAS TEACHER B -->
				  	<v-row v-if="verGraficaTeacherB">
				  		<v-col cols="12" :lg="`${pregunta.cols}`" v-for="(pregunta, i) in preguntasSeccion3" :key="i">
					  		<v-card class="">
					  			<v-card-title>
					  			  <span class="text-subtitle-1  text-typo"><b>{{ pregunta.pregunta }} </b></span>
					  			</v-card-title>
				          <v-card-text class="position-relative">
				          	<vue-apex-charts
					            :type="pregunta.chartOptions.chart.type" height="350" :options="pregunta.chartOptions" :series="pregunta.series"
					          ></vue-apex-charts>
				          </v-card-text>
				        </v-card>
				  		</v-col>
				  	</v-row>

				  	<!-- TABLAS TEACHER A -->
				  	<v-row v-if="verGraficaTeacherB">
				  		<v-col cols="12">
					  		<v-card class="">
					  			<v-card-title>
					  			  <span class="text-h6  text-gradient"><b> Porcentajes Teacher B </b></span>
					  			</v-card-title>
				          <v-card-text class="position-relative">
				          	<v-data-table
									    :headers="tablaTeacherB.headers"
									    :items="tablaTeacherB.teachers"
									    sort-by="calories"
									    class="elevation-0"
									    dense
									  >
									  </v-data-table>
				          </v-card-text>
				        </v-card>
				  		</v-col>
				  	</v-row>


				  	<!-- BARRA NIVELES INDIVIDUAL POR NIVELES -->
				  	<v-row>
				  		<v-col cols="12">
					  		<v-card class="">
				          <v-img
				            class="white--text align-end"
				          />
				          <v-card-text class="position-relative">
				          	<div class="d-flex flex-no-wrap ">
				              <div>
				                <v-card-title class="text-h6 text-typo">Resultados individuales por <span class="text-h4 mx-2 text-gradient"> <b> NIVEL</b></span>
				              	</v-card-title>
				              </div>
				            </div>
				          </v-card-text>
				          <div class="check">
					          <v-switch 
					          	label="Gráficas" 
					          	v-model="verTablaNivel"
					          	fixed
					          ></v-switch>
				          </div>
				        </v-card>
				  		</v-col>
				  	</v-row>

				  	<v-row v-if="verTablaNivel">
				  		<v-col cols="12" :lg="`${pregunta.cols}`" v-for="(pregunta, i) in encuestasNivel" :key="i">
					  		<v-card class="">
					  			<v-card-title>
					  			  <span class="text-h6  text-gradient"><b>{{ pregunta.pregunta }} </b></span>
					  			</v-card-title>
				          <v-card-text class="position-relative">
				          	<v-data-table
									    :headers="pregunta.headers"
									    :items="pregunta.niveles"
									    sort-by="calories"
									    class="elevation-0"
									    :items-per-page="20"
									    dense
									    hide-default-footer
									  >
									  </v-data-table>
				          </v-card-text>
				        </v-card>
				  		</v-col>
				  	</v-row>

      	  </v-card-text>
      	</v-card>

      </v-col>
    </v-row>

    <!-- Dialogo para agregar un reemplazo del teacher -->
    <v-dialog
      v-model="dialogPreguntas"
      max-width="1200px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title class="text-subtitle-1">
          Preguntas abiertas de la sección
          <v-spacer></v-spacer>
          <v-btn 
            class="mr-2" 
            small dark color="green"
            @click="exportarPreguntas()"
          >
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
        	<v-text-field
        	  label="Buscar"
        	  filled
        	  single-line
        	  v-model="search"
        	  append-icon="mdi-magnify"
        	  dense
        	></v-text-field>
          <v-data-table
          	:search="search"
				    :headers="headers"
				    :items="data"
				    sort-by="calories"
				    class="elevation-0"
				    dense
				  >
				  </v-data-table>
        </v-card-text>
        <v-card-actions>
        	<v-btn color="red"     small rounded @click="dialogPreguntas = false" dark>Cancelar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import VueApexCharts         from 'vue-apexcharts'

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

  	components:{
      Alerta,
      carga,
      VueApexCharts
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      ciclos:[],
      ciclo:null,

      dialogReemplazo: false,

      // Filtros avanzados

      teachersFiltros:[],
			teacherFilter: null,

			dialogMaestro: false,

			escuela: 1,

			alumnosEncuestados:0,
			totalAlumnos:0,
			porcentaje:0,
			preguntasSeccion1:[],
			preguntasSeccion2:[],
			preguntasSeccion3:[],
			encuestasNivel:[],
			tablaTeacherA:[],
			tablaTeacherB:[],
			preguntasAbiertasTacherA:[],
			preguntasAbiertasTacherB:[],
			preguntasAbiertasGeneral:[],

			verGraficaIniciales: true,
			verGraficaTeacherA: true,
			verGraficaTeacherB: true,
			verTablaNivel: true,

			headersGeneral: [
	    	{ text: 'Alumno'    , value: 'alumno' },
        { text: 'Pregunta'  , value: 'pregunta' },
        { text: 'Respuesta' , value: 'respuesta_abierta' },
        { text: 'Grupo'     , value: 'grupo' },
        { text: 'Nivel'     , value: 'id_nivel' },
	    ],

	    headersTeachers: [
	    	{ text: 'Alumno'    , value: 'alumno'   },
        { text: 'Pregunta'  , value: 'pregunta' },
        { text: 'Respuesta' , value: 'respuesta_abierta' },
        { text: 'Grupo'     , value: 'grupo'    },
        { text: 'Nivel'     , value: 'id_nivel' },
        { text: 'Teacher'   , value: 'teacher'  },
	    ],

	    data: [],
	    dialogPreguntas:false,
	    headers:[],
	    search:''
      
    }),

    watch: {
      // Consultar cuando haya un cambio en el ciclo y este sea un ciclo seleccionado y no un ciclo vacio
      ciclo () {
        if(this.ciclo){
          this.consultar()
        }
      }
    },

    async created () {
      await this.initialize()
    },

    methods: {
      // Funcion para cargar datos iniciales
      initialize () {
      	this.cargar = true
        // Vaciar los ciclos
        this.ciclos = []
        // Obtener todos los ciclos
        this.$http.get('roles.ciclos.all').then(response=>{
          // hacer un ciclo ya que los ciclos vienen varios como inscripciones y todo eso
          // y solo queremos los ciclos que en su nombre dicen ciclo 
          for(const i in response.data){
            //  si es diferente a FE
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              // Y es igual a FE, se agrega a ciclos
              // por qué es diferente a FE? por que es por estetica, mostrar solo los ciclos normales, ya que los ciclos tienen ligado el ciclo FE
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }

      		this.cargar = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      consultar () {
      	if( !this.ciclo && this.escuela ){
      		return this.validarErrorDirecto('Selecciona un ciclo por favor')
      	}

      	this.alumnosEncuestados = 0
				this.totalAlumnos       = 0
				this.porcentaje         = 0
				this.preguntasSeccion1  = []
				this.preguntasSeccion2  = []
				this.preguntasSeccion3  = []
				this.encuestasNivel     = []
				this.tablaTeacherA      = []
				this.tablaTeacherB      = [] 
				this.preguntasAbiertasTacherA = []
				this.preguntasAbiertasTacherB = []
				this.preguntasAbiertasGeneral = []
				this.data                     = []

      	const payload = {
      		escuela             : this.escuela,
      		id_ciclo            : this.ciclo.id_ciclo,
      		id_ciclo_relacionado: this.ciclo.id_ciclo_relacionado
      	}
      	
      	this.cargar = true
        this.$http.post('encuestas.satisfaccion', payload ).then(response=>{
          this.alumnosEncuestados        = response.data.alumnosEncuestados
					this.totalAlumnos              = response.data.totalAlumnos
					this.porcentaje                = response.data.porcentaje
					this.preguntasSeccion1         = response.data.preguntasSeccion1
					this.preguntasSeccion2         = response.data.preguntasSeccion2
					this.preguntasSeccion3         = response.data.preguntasSeccion3
					this.encuestasNivel            = response.data.encuestasNivel
					this.tablaTeacherA             = response.data.tablaTeacherA
					this.tablaTeacherB             = response.data.tablaTeacherB
					this.preguntasAbiertasTacherA  = response.data.preguntasAbiertasTacherA
					this.preguntasAbiertasTacherB  = response.data.preguntasAbiertasTacherB
					this.preguntasAbiertasGeneral  = response.data.preguntasAbiertasGeneral

          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      abrirPreguntas( data, headers ){
      	this.headers = headers
      	this.data    = data
      	console.log( data )
      	this.dialogPreguntas = true
      },

      exportarPreguntas(){
      	this.exportExcel( this.data  , 'preguntas_abiertas')
      },

      exportar( ){
      	this.exportExcel( this.filterGrupos  , 'ROL_CLASES')
      }
    },
  }

</script>
<style>
  .v-data-table__wrapper{

  }

  .v-data-table__wrapper::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-data-table__wrapper::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  .v-data-table__wrapper::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

	.text-gradient{
		color: #82d616!important;
	}

  .text-typo {
    color: #344767!important;
	}

	.check {
	  position: absolute;
	  top: -10px;
	  right: 90px;
	  width: 30px;
	}


	.v-btn--example {
    bottom: 0;
    position: absolute;
    margin: 0 0 16px 16px;
    right: 10px;
  }
</style>